import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
import Rotulo from "./rotulos";
import { ObjetoEducacional } from "../../models/ObjetoEducacional";
import { cn } from "../utils/utils";
import {
  getDescricaoTipoObjeto,
  getIconeTipoObjeto,
  getTipoObjeto,
} from "../utils/tipo-objeto";
import CalendarBlue from "../../assets/svgs/icons/calendar.svg";
import DeclaracaoIcon from "../../assets/svgs/new/certificado.svg";
import { SmartLinkNovaHome } from "./SmartLinkNovaHome";
import { Favoritar } from "./Favoritar";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Badge } from "./ui/badge";
import { Bookmark, EllipsisVertical, Send } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown";

import { useModalPlaylist } from "../../hooks/useModalPlaylist";
import Compartilhar from "../../pages/OEPage/components/Compartilhar";
import { useRef, useState } from "react";

export enum typeObjetoEducacional {
  VIDEO = "VÍDEO",
  QUESTIONARIO = "QUESTIONÁRIO",
  TEXTO = "TEXTO",
  IMAGEM = "IMAGEM",
  PDF = "PDF",
  AUDIO = "ÁUDIO",
}

export const getIcon = (type: string) => {
  switch (type) {
    case typeObjetoEducacional.VIDEO:
      return "/assets/svg/tipo-objetos/video.svg";
    case typeObjetoEducacional.QUESTIONARIO:
      return "/assets/svg/tipo-objetos/questionario.svg";
    case typeObjetoEducacional.TEXTO:
      return "/assets/svg/tipo-objetos/texto.svg";
    case typeObjetoEducacional.IMAGEM:
      return "/assets/svg/tipo-objetos/imagem.svg";
    case typeObjetoEducacional.PDF:
      return "/assets/svg/tipo-objetos/pdf.svg";
    case typeObjetoEducacional.AUDIO:
      return "/assets/svg/tipo-objetos/audio.svg";
    default:
      return "";
  }
};

export default function CardObjetoEducacional({
  objetoEducacional,
  classNameItems,
}: {
  objetoEducacional: ObjetoEducacional;
  classNameItems?: {
    rotulos?: string;
  };
}) {
  function formatarTempoMinutos(minutes: string) {
    const totalMinutes = Number(minutes);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
    const formattedSeconds = "00";

    if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${formattedMinutes.toString().padStart(2, "0")}:${formattedSeconds.toString().padStart(2, "0")}`;
    } else {
      return `${formattedMinutes.toString().padStart(2, "0")}:${formattedSeconds.toString().padStart(2, "0")}`;
    }
  }

  return (
    <>
      <Card className="group flex h-full flex-col gap-2 shadow-none">
        <SmartLinkNovaHome
          oe={objetoEducacional}
          onShowCertificate={() => {}}
          isListCertificados={false}
        >
          <CardHeader className="relative h-[188.42px] md:h-[227.66px]">
            <img
              alt="imagem capa objeto educacional"
              src={objetoEducacional?.imagemPreviewUrl ?? ""}
              width={300}
              height={150}
              className={cn(
                "absolute left-0 top-0 h-full w-full rounded-xl border-4 object-cover object-top group-hover:border-[#B1E3D8] group-focus:border-[#B1E3D8]",
                objetoEducacional?.isPatrocinado
                  ? "border-4 border-[#626466]"
                  : "",
              )}
            />
            <img
              src={getTipoObjeto(objetoEducacional?.tipoObjetoId)}
              alt="ícone tipo objeto educacional"
              width={60}
              height={60}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            />
            <div
              className={`absolute left-[24px] top-[17.78px] max-w-sm md:left-6 md:top-6 ${classNameItems?.rotulos}`}
            >
              <Rotulo
                rotuloName={objetoEducacional?.descricaoRotulo}
                id={objetoEducacional?.idRotulo}
              />
            </div>
            <div className="absolute right-5 top-5 md:right-6 md:top-6">
              <Favoritar
                idOe={objetoEducacional?.id?.toString() || "0"}
                color="yellow"
                item={{
                  id: objetoEducacional?.id?.toString()!,
                  titulo: objetoEducacional.titulo!,
                }}
              />
            </div>
            {objetoEducacional && (
              <Badge className="absolute bottom-[19.78px] right-6 border-0 border-none bg-black-900">
                <span className="text-xxs font-bold text-white-200 md:text-xs">
                  {formatarTempoMinutos(objetoEducacional?.tempoMedioDuracao)}
                </span>
              </Badge>
            )}

            {objetoEducacional?.finalizado && (
              <span className="absolute bottom-[19.78px] left-[19.78px] text-xxs font-bold text-white-200 md:bottom-6 md:left-6 md:text-xs">
                Visualizado
              </span>
            )}
          </CardHeader>
        </SmartLinkNovaHome>
        <CardContent className="flex flex-col items-start justify-between gap-2 p-0">
          {objetoEducacional?.isPatrocinado && (
            <span className=" text-[10px] font-bold text-[#626466]">
              CONTEÚDO PATROCINADO
            </span>
          )}
          <span className="flex w-full justify-between text-sm font-semibold leading-4 text-[#111316] md:text-base tablet:text-xs tablet:leading-3">
            <SmartLinkNovaHome
              oe={objetoEducacional}
              onShowCertificate={() => {}}
              isListCertificados={false}
            >
              {objetoEducacional?.titulo}
            </SmartLinkNovaHome>
            <MoreOptions
              idOe={objetoEducacional?.id}
              titulo={objetoEducacional.titulo}
              descricao={objetoEducacional.titulo}
              imagemPreview={objetoEducacional.imagemPreviewUrl}
              shareUrl={getUrl(objetoEducacional)}
              tipoOe={objetoEducacional?.tipoObjetoId}
            />
          </span>
        </CardContent>
        <CardFooter className="flex flex-col items-start gap-[3px] p-0">
          <div className="flex flex-row gap-2">
            <div className="flex flex-row items-center gap-1">
              {getIconeTipoObjeto(
                objetoEducacional?.tipoObjetoId,
                "#004F92",
                20,
              )}
              <span className="text-xxs font-semibold text-[#111316] md:text-[11px]">
                {getDescricaoTipoObjeto(objetoEducacional?.tipoObjetoId)}
              </span>
            </div>

            <div className="flex flex-row items-center gap-1">
              <img
                src={CalendarBlue}
                alt="icon"
                width={20} //24
                height={20} //24
                className="size-[17.76px] md:size-6 tablet:size-[15.64px]"
              />
              <span className="text-xxs font-semibold text-[#111316] md:text-[11px]">
                {format(
                  new Date(objetoEducacional?.dataProducao),
                  "dd MMM yyyy",
                  {
                    locale: ptBR,
                  },
                )}
              </span>
            </div>
            {objetoEducacional?.possuiCertificado && (
              <div className="flex flex-row items-center gap-[3px]">
                <img
                  src={DeclaracaoIcon}
                  alt="ícone certificado"
                  width={20} //24
                  height={20} //24
                  className="size-[17.76px] md:size-6 tablet:size-[15.64px]"
                />

                <span className="text-xxs font-semibold leading-18 text-[#111316] md:text-[11px] md:leading-4 tablet:text-xxs">
                  Declaração de participação
                </span>
              </div>
            )}
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

const formatarUrl = (oeType: string, oeId: string, videoId?: string) => {
  switch (oeType) {
    case "Áudio":
      return `/oe/${oeId}/podcast/`;
    case "Imagem":
      return `/oe/${oeId}/imagem/preview`;
    case "Questionário":
    case "Quizz":
      return `/oe/${oeId}/quiz`;
    case "Simulação":
      return `/oe/${oeId}/simulacao/`;
    case "Leitura":
    case "Texto":
      return `/oe/${oeId}/leitura/preview/`;
    case "Live":
      return `/oe/${oeId}/live${!!videoId ? `/${videoId}` : ""}`;
    case "Vídeo":
      return `/oe/${oeId}/video${!!videoId ? `/${videoId}` : ""}`;
    default:
      return "";
  }
};

const getUrl = (oe: ObjetoEducacional) => {
  const oeType = oe?.tipoOe?.toString() || "";
  let _url = formatarUrl(oe.tipoOe, oe.id.toString());

  if (!!oe.idVideoAudio) {
    _url = formatarUrl(oeType, oe.id.toString(), oe.idVideoAudio.toString());
  }

  return _url;
};

export function MoreOptions({
  idOe,
  imagemPreview,
  titulo,
  descricao,
  shareUrl,
  tipoOe,
}: {
  idOe?: number;
  imagemPreview: string;
  titulo: string;
  descricao: string;
  shareUrl: string;
  tipoOe: number;
}) {
  const mostrarModalPlaylist = useModalPlaylist();
  const [anchorElExt, setAnchorElExt] = useState<any>(null);
  const refDiv = useRef(null);

  return (
    <div ref={refDiv}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button aria-label="Opções" aria-haspopup="menu">
            <EllipsisVertical className="size-5" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {tipoOe !== 7 && (
            <DropdownMenuItem
              onClick={() => {
                mostrarModalPlaylist.mostrar({
                  idOe: idOe!,
                });
              }}
              className="flex cursor-pointer gap-3 px-3 py-2 text-black-900 hover:bg-slate-50"
            >
              <Bookmark />
              Salvar na playlist
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() => {
              setAnchorElExt(refDiv.current);
            }}
            className="flex cursor-pointer gap-3 px-3 py-2 text-black-900 hover:bg-slate-50"
          >
            <Send className="size-5" />
            Compartilhar
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {anchorElExt && (
        <Compartilhar
          item={{
            descricao: descricao,
            titulo: titulo,
            url: imagemPreview,
            id: idOe?.toString(),
          }}
          shareUrl={shareUrl}
          color="white"
          newIcon={true}
          clickExt={true}
          anchorElExt={anchorElExt}
          setAnchorElExt={(e: any) => setAnchorElExt(e)}
        />
      )}
    </div>
  );
}
