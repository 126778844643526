import { Button } from "../../../shared/components/ui/button";
import Container from "../../../shared/components/Container";
import Rotulo from "../../../shared/components/rotulos";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../../shared/components/ui/breadcrumb";
import * as Icon from "../../../assets/svgs";
import Vimeo, {
  PauseEvent,
  PlayEvent,
  TimeUpdateEvent,
} from "@u-wave/react-vimeo";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import {
  OEsAssistidosPorUsuarioEntity,
  ObjetoEducacionalEntity,
  PerfilDeAutorEntity,
} from "../../../generated/graphql";
import { UserContext, getUserContext } from "../../../context/UserContext";
import { cn } from "../../../lib/utils";
import Tags from "../../../shared/components/Tags";
import { AboutAuthors } from "../../OEPage/components/SobreAutores";
import BarraInteracaoOe from "../../OEPage/components/BarraInteracaoOe";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import useOeConsume from "../../../hooks/useOeConsume";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";
import { Loader, ModalService } from "../../../shared/components";
import { useScrollTop } from "../../../shared/hooks/useScrollTop";
import { ScrollArea } from "../../../shared/components/ui/scroll-area";
import ModalPlaylist from "../../../shared/components/ModalPlaylist";
import OePDFWatch from "../../OEPage/OePDFPage/OePDFWatch";
import { QuizComponent } from "../../OEPage/OeQuizPage/components/Quiz";
import Flashcard from "../../../shared/components/Flashcard";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../shared/components/ui/dropdown";
import { EllipsisVertical, Pencil, Trash2 } from "lucide-react";
import { AlertContext } from "../../../context/UseAlert";
import { useModalPlaylistConfirmDelete } from "../../../hooks/useModalConfirmDelete";
import { useModalDeleteItem } from "../../../hooks/useModalDeleteItem";

interface PlaylistResponse {
  banner: {
    bannerFixo: string;
    bannerFixoTablet: string;
    bannerModal: string;
    descricao: string;
    linkUrl: string;
    id: string;
    titulo: string;
  };
  detalhesPlaylist: PlaylistDetalhe[];
  tituloPlaylist: string;
  playlistVisualizada?: boolean;
}

interface PlaylistDetalhe {
  id: number;
  titulo: string;
  resumo: string;
  duracao: string;
  videoUrl: string;
  idVideoAudio: number;
  bannerImagemUrl: string;
  linkBannerUrl: string;
  tipoOe: string;
  imagemUrl: string;
  pdfUrl: string;
  autores: {
    id: number;
    oeId: number;
    nome: string;
    fotoPerfil: string | null;
    email: string | null;
    linkCurriculo: string | null;
    nomeProjeto: string | null;
    linkProjeto: string | null;
    cargo: string;
  }[];
  palavrasChaves: {
    id: number;
    descricao: string;
  }[];
}

export interface CardsMultiplosProps {
  objeto: PlaylistDetalhe;
  index: number;
  loading: boolean;
  oesAssistidos?: OEsAssistidosPorUsuarioEntity[];
  videoAtual: any;
  setObjetoSelecionado: (oe: any) => void;
  objetoSelecionado: ObjetoEducacionalEntity;
  quantidadeOe?: number;
}

async function getDetalhePlaylist(
  idplaylist: number,
): Promise<PlaylistResponse> {
  const response = await fetchApi(
    `playlists/playlist-detalhe?playlistId=${idplaylist}`,
    "GET",
  );
  return response.json();
}

async function getDetalhePersonalizadaPlaylist(
  idplaylist: number,
): Promise<PlaylistResponse> {
  try {
    const response = await fetchApi(
      `playlists/usuario/playlist-detalhe?playlistId=${idplaylist}`,
      "GET",
    );
    if (response.status === 401) {
      throw Error("401")
    }
    return response.json();
  } catch (error: any) {
    throw error;
  }
}

export default function DetalhePlaylist() {
  const scrollTop = useScrollTop();
  const { user } = useContext(UserContext);
  const { showMessage } = useContext(AlertContext);
  const { saveOeConsume } = useOeConsume();

  const [playlistsDetalhe, setPlaylistsDetalhe] = useState<PlaylistResponse>(
    {} as PlaylistResponse,
  );
  const [isShowProximaAula, setIsShowProximaAula] = useState<boolean>(false);
  const [objetoSelecionado, setObjetoSelecionado] = useState<
    ObjetoEducacionalEntity | any
  >();
  const params = useParams<{ id: string }>();
  const handleTimeUpdateRef = useRef<any>(null);

  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    scrollTop();
  }, [objetoSelecionado]);

  const location = useLocation();
  const isPlaylistPersonalizada = location.pathname.includes(
    "detalhe-personalizada",
  );

  useEffect(() => {
    async function fetchData() {
      try {
        if (!params?.id) return;
        setLoading(true);
        const data = isPlaylistPersonalizada
          ? await getDetalhePersonalizadaPlaylist(Number(params?.id))
          : await getDetalhePlaylist(Number(params?.id));
        setPlaylistsDetalhe(data);
        if (!objetoSelecionado && data?.detalhesPlaylist?.length > 0) {
          setObjetoSelecionado(data?.detalhesPlaylist[0]);
        }
        setLoading(false);
      } catch (error) {
        if (error?.toString().includes("400") || error?.toString().includes("401")) {
          showMessage("Ops! Não encontramos a playlist que você está procurando.", "ERRO");
          history.push('/');
        }

        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const _oeAssistido = user?.attributes?.OesConsumidosParcialmente?.data.find(
    (item) =>
      (objetoSelecionado?.tipoOe === "Vídeo" &&
        !!item?.attributes?.VideoAudioId &&
        item?.attributes?.VideoAudioId.toString() ===
          objetoSelecionado?.idVideoAudio?.toString()) ||
      (objetoSelecionado?.tipoOe !== "Vídeo" &&
        item.attributes?.Oes_Assistidos?.data.some(
          (a) => a.id?.toString() === objetoSelecionado?.id.toString(),
        )),
  );

  const updateTime = (event: any) => {
    const currentDuration = event?.duration || 0;
    const currentSeconds = event?.seconds || 0;
    if (currentDuration - 60 <= currentSeconds) {
      const indexSelecionadoObjGeral =
        playlistsDetalhe?.detalhesPlaylist?.findIndex(
          (a) => a.id === objetoSelecionado.id,
        );
      if (indexSelecionadoObjGeral <= -1) {
        return;
      }

      const isMoreObjeto =
        (playlistsDetalhe?.detalhesPlaylist?.length || 0) - 1 >
        indexSelecionadoObjGeral;

      setIsShowProximaAula(isMoreObjeto);
    } else {
      setIsShowProximaAula(false);
    }
  };

  useEffect(() => {
    const playlist = playlistsDetalhe?.detalhesPlaylist?.filter((a) => {
      const userOeConsumido =
        user?.attributes?.OesConsumidosParcialmente?.data.some(
          (b) =>
            b.attributes?.Oes_Assistidos?.data.some(
              (i) => i?.id?.toString() === a?.id?.toString(),
            ) && b.attributes?.segundos! === b.attributes?.duracao!,
        );

      return !userOeConsumido;
    });

    if (
      playlist?.filter((a) => a.id !== objetoSelecionado?.id).length === 0 &&
      !playlistsDetalhe.playlistVisualizada &&
      !isPlaylistPersonalizada
    ) {
      ModalService.show({
        content: (
          <ModalPlaylist
            imagem={playlistsDetalhe?.banner?.bannerModal}
            titulo={playlistsDetalhe.banner.titulo}
            descricao={playlistsDetalhe.banner.descricao}
            url={playlistsDetalhe?.banner?.linkUrl}
          />
        ),
        notFullScreenMobile: true,
        closeOutsideModal: true,
      });
      fetchApi(
        `playlists/set-visualizacao?playlistId=${params.id}`,
        "POST",
      ).then((data) => {
        console.log(data);
      });
    }
  }, [playlistsDetalhe]);

  useEffect(() => {
    const timeUpdateSubject = new Subject();

    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(600));

    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe(async (data: any) => {
      // Obtém o progresso atual do vídeo
      const currentDuration = data.event.duration;
      const currentSeconds = data.event.seconds;

      const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.VideoAudioId &&
            item?.attributes?.VideoAudioId.toString() ===
              objetoSelecionado?.idVideoAudio?.toString(),
        );

      if (objetoSelecionado?.idVideoAudio) {
        localStorage.setItem(
          "UPDATE_VIDEO",
          JSON.stringify({
            id: oeAssistido?.id,
            infograficoId: oeAssistido?.attributes?.ImagemOeId,
            leituraId: oeAssistido?.attributes?.LeituraOeId,
            oe: objetoSelecionado.id,
            partialDuration: parseInt(currentSeconds.toString().split(".")[0]),
            totalDuration: parseInt(currentDuration.toString().split(".")[0]),
            user: user?.id,
            videoAudioId: objetoSelecionado?.idVideoAudio,
          }),
        );
      }

      await save(currentSeconds, currentDuration, data.objetoEducacional);

      const playlist = playlistsDetalhe?.detalhesPlaylist?.filter((a) => {
        const userOeConsumido =
          user?.attributes?.OesConsumidosParcialmente?.data.some(
            (b) =>
              b.attributes?.Oes_Assistidos?.data.some(
                (i) => i?.id?.toString() === a?.id?.toString(),
              ) && b.attributes?.segundos! === b.attributes?.duracao!,
          );

        return !userOeConsumido;
      });

      if (
        playlist?.filter((a) => a.id !== objetoSelecionado?.id).length === 0 &&
        !playlistsDetalhe.playlistVisualizada
      ) {
        ModalService.show({
          content: (
            <ModalPlaylist
              imagem={playlistsDetalhe.banner.bannerModal}
              titulo={playlistsDetalhe.banner.titulo}
              descricao={playlistsDetalhe.banner.descricao}
              url={objetoSelecionado?.linkBannerUrl}
            />
          ),
          notFullScreenMobile: true,
          closeOutsideModal: true,
        });
        fetchApi(
          `playlists/set-visualizacao?playlistId=${params.id}`,
          "POST",
        ).then((data) => {
          console.log(data);
        });
      }
    });

    // Lembre-se de cancelar a inscrição ao desmontar o componente
    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };
  }, []);

  const handleFinish = async (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    info?: PlaylistDetalhe,
  ) => {

    if (info) {
      await saveOeConsume({
        partialDuration: 1,
        totalDuration: 1,
        oe: info?.id,
        user: user?.id,
        id: _oeAssistido?.id
      });
    }

    const playlist = playlistsDetalhe?.detalhesPlaylist?.filter((a) => {
      const userOeConsumido =
        user?.attributes?.OesConsumidosParcialmente?.data.some(
          (b) =>
            b.attributes?.Oes_Assistidos?.data.some(
              (i) => i?.id?.toString() === a?.id?.toString(),
            ) && b.attributes?.segundos! === b.attributes?.duracao!,
        );

      return !userOeConsumido;
    });

    if (!info) {
      await saveOeConsume({
        partialDuration: 1,
        totalDuration: 1,
        oe: objetoSelecionado.id,
        videoAudioId: objetoSelecionado.idVideoAudio,
        user: user?.id,
        id: _oeAssistido?.id
      });
    }

    if (
      playlist?.filter((a) => a.id !== objetoSelecionado?.id).length === 0 &&
      !playlistsDetalhe.playlistVisualizada
    ) {
      ModalService.show({
        content: (
          <ModalPlaylist
            imagem={playlistsDetalhe.banner.bannerModal}
            titulo={playlistsDetalhe.banner.titulo}
            descricao={playlistsDetalhe.banner.descricao}
            url={objetoSelecionado?.linkBannerUrl}
          />
        ),
        notFullScreenMobile: true,
        closeOutsideModal: true,
      });
      fetchApi(
        `playlists/set-visualizacao?playlistId=${params.id}`,
        "POST",
      ).then((data) => {
        console.log(data);
      });
    }
  };

  const save = async (
    seconds: number,
    duration: number,
    objetoEducacional: any,
  ) => {
    if (!user || !objetoEducacional?.idVideoAudio) return;

    const oeAssistido = user?.attributes?.OesConsumidosParcialmente?.data.find(
      (item: any) =>
        item.attributes?.VideoAudioId &&
        objetoEducacional?.attributes?.VideoAudio &&
        item.attributes?.VideoAudioId!.toString() ===
          objetoEducacional?.idVideoAudio?.toString(),
    );

    /** Para nao perder a declaracao caso o usuario ja tenha concluido o consumo desse video */
    if (
      !!oeAssistido &&
      oeAssistido?.attributes?.duracao &&
      oeAssistido?.attributes?.duracao > 0 &&
      oeAssistido?.attributes?.duracao === oeAssistido?.attributes?.segundos
    ) {
      return;
    }

    const videoAudioId = objetoEducacional?.idVideoAudio?.toString() || "";
    await saveOeConsume({
      id: oeAssistido?.id,
      videoAudioId: parseInt(videoAudioId),
      user: user?.id,
      partialDuration: parseInt(seconds?.toString().split(".")[0]),
      totalDuration: parseInt(duration?.toString().split(".")[0]),
      oe: objetoEducacional.id,
    });
  };

  const gtm = useGtm();

  const handlePlayEvent = async (e: PlayEvent) => {
    handleTimeUpdateRef.current(e, objetoSelecionado);

    // GTM doc linha 86!!!
    gtm.push(
      EDataLayerEventCategory.ADE_OE_INTERACAO_VIDEO,
      EDataLayerEventAction.CLIQUE,
      {
        label: objetoSelecionado?.titulo,
      },
    );
  };

  const playListFinalizada =
    playlistsDetalhe?.detalhesPlaylist?.findIndex(
      (a) => a.id === objetoSelecionado?.id,
    ) <
    (playlistsDetalhe?.detalhesPlaylist?.length || 0) - 1;

  const handleNextOe = (objetoEducacionalSelecionado: any | null) => {
    const indexSelecionadoObj =
      playlistsDetalhe.detalhesPlaylist?.findIndex(
        (play) => play?.id === objetoEducacionalSelecionado?.id,
      ) || 0;
    if (
      indexSelecionadoObj <
      (playlistsDetalhe.detalhesPlaylist.length || 0) - 1
    ) {
      const objSelecionado =
        playlistsDetalhe.detalhesPlaylist[indexSelecionadoObj + 1];
      save(
        _oeAssistido?.attributes?.duracao || 1,
        _oeAssistido?.attributes?.duracao || 1,
        objetoSelecionado,
      );
      setIsShowProximaAula(false);
      setObjetoSelecionado(objSelecionado || null);
    }
  };

  const extImagem = objetoSelecionado?.imagemUrl?.split(".");
  const hasPDF = extImagem?.includes("pdf");

  const finalizado =
    (_oeAssistido?.attributes?.segundos || 0) >=
    (_oeAssistido?.attributes?.duracao || 1);

  return (
    <>
      <Loader show={loading} />
      <div className="bg-[#F5F8FF]">
        <Container className="py-6">
          <div className="flex w-full flex-col items-start justify-start gap-3 !text-start ">
            <Breadcrumb className="flex justify-start !text-xxs font-normal  md:text-xs !text-[#111316]">
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink className="text-[#111316] hover:text-[#111316] hover:font-semibold focus:text-[#111316] focus:font-semibold " href="/">Home</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink className="text-[#111316] hover:text-[#111316] hover:font-semibold focus:text-[#111316] focus:font-semibold" href="/playlists">Playlists</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink className="text-[#111316]  hover:text-[#111316]"
                  // href={`/oe/${props?.oe?.id}/video`}
                  >
                    {playlistsDetalhe.tituloPlaylist}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
            <div className="flex">
              <Rotulo
                key={1}
                id={1}
                rotuloName={"Playlist"}
                isMetodoEnsino={true}
              />
            </div>
            <h3 className="text-xxl font-semibold leading-[26.82px] text-[#004F92]">
              {playlistsDetalhe?.tituloPlaylist}
            </h3>
          </div>
          {/* .split( ".", ) */}
          <div className="mt-8 flex w-full flex-col gap-6 md:flex-row">
            <div className="flex w-full flex-col gap-2 overflow-y-auto">
              {!!user && objetoSelecionado && (
                <div className="w-full overflow-y-auto">
                  {objetoSelecionado?.tipoOe === "Imagem" ? (
                    <>
                      {!hasPDF ? (
                        <div
                          className="!mb-5"
                          data-text-container={
                            objetoSelecionado?.tipoOe === "imagem"
                              ? "infografico"
                              : ""
                          }
                        >
                          <div
                            key={objetoSelecionado.id}
                            // className={`zoom zoom-${current}x`}
                          >
                            {/* {objetoSelecionado.attributes?.SecoesTexto!.map(
                              (text, index) => (
                                <div key={text?.id}>
                                  <h2>{criarTituloSecaoTexto(index)}</h2>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: text?.Secao,
                                    }}
                                  />
                                </div>
                              ),
                            )} */}

                            <div data-infografico-image>
                              {objetoSelecionado.imagemUrl && (
                                <img
                                  src={objetoSelecionado.imagemUrl}
                                  alt={objetoSelecionado.Titulo!}
                                  title={objetoSelecionado.Titulo!}
                                />
                              )}
                            </div>
                          </div>
                          <div data-button-finish>
                            <Button
                              data-testid="btn-concluir-conteudo"
                              disabled={finalizado}
                              type="button"
                              variant="primary"
                              onClick={(ev) => {
                                handleFinish(ev, objetoSelecionado);
                                handleNextOe(objetoSelecionado);
                              }}
                            >
                              {finalizado ? "Concluído" : (playListFinalizada ? "Ir para o próximo" : "Concluir Conteúdo")}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <OePDFWatch
                            residenciaMedica={true}
                            pdf={{
                              id: objetoSelecionado?.id!,
                              LinkPDF:  hasPDF ? objetoSelecionado?.imagemUrl! : objetoSelecionado?.pdfUrl!,
                              data: {
                                attributes: {
                                  url:  hasPDF ? objetoSelecionado?.imagemUrl! : objetoSelecionado?.pdfUrl!,
                                  hash: hasPDF ? objetoSelecionado?.imagemUrl! : objetoSelecionado?.pdfUrl!,
                                },
                              },
                            }}
                            idOe={objetoSelecionado.id!}
                          />
                          <div data-button-finish>
                            <Button
                              data-testid="btn-concluir-conteudo"
                              type="button"
                              disabled={finalizado}
                              variant="primary"
                              onClick={(ev) => {
                                handleFinish(ev, objetoSelecionado);
                                handleNextOe(objetoSelecionado);
                              }}
                            >
                              {finalizado ? "Concluído" :  (playListFinalizada ? "Ir para o próximo" : "Concluir Conteúdo")}
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  ) : objetoSelecionado?.tipoOe === "PDF" ? (
                    <>
                      <OePDFWatch
                        residenciaMedica={true}
                        pdf={{
                          id: objetoSelecionado?.id!,
                          LinkPDF: hasPDF ? objetoSelecionado?.imagemUrl! : objetoSelecionado?.pdfUrl!,
                          data: {
                            attributes: {
                              url:  hasPDF ? objetoSelecionado?.imagemUrl! : objetoSelecionado?.pdfUrl!,
                              hash:  hasPDF ? objetoSelecionado?.imagemUrl! : objetoSelecionado?.pdfUrl!,
                            },
                          },
                        }}
                        idOe={objetoSelecionado.id!}
                      />
                      <div data-button-finish>
                        <Button
                          data-testid="btn-concluir-conteudo"
                          type="button"
                          disabled={finalizado}
                          variant="primary"
                          onClick={(ev) => {
                                handleFinish(ev, objetoSelecionado);
                                handleNextOe(objetoSelecionado);
                              }}
                        >
                          {finalizado ? "Concluído" :  (playListFinalizada ? "Ir para o próximo" : "Concluir Conteúdo")}
                        </Button>
                      </div>
                    </>
                  ) : objetoSelecionado?.tipoOe === "Questionário" ? (
                    <>
                      <QuizComponent
                        oe={{
                          id: objetoSelecionado.id,
                          attributes: {
                            Descricao: "",
                            Titulo: objetoSelecionado.titulo,
                            ImagemCapa: objetoSelecionado.imagemUrl,
                            ImagemPreview: objetoSelecionado.imagemUrl,
                            TempoMedioDuracao: objetoSelecionado.tempoDuracao,
                            Resumo: objetoSelecionado.resumo,
                            Rotulos: {
                              data: [
                                {
                                  attributes: {
                                    titulo: objetoSelecionado.descricaoRotulo,
                                  },
                                },
                              ],
                            },
                            Quizzes: {
                              data: objetoSelecionado.questionario.map(
                                (a: any) => ({
                                  id: a.id,
                                }),
                              ),
                            },
                          },
                        }}
                        user={user}
                        onFinish={async () => {
                          if (objetoSelecionado.tipoOe === "Questionário") {
                            await saveOeConsume({
                              partialDuration: 1,
                              totalDuration: 1,
                              oe: objetoSelecionado?.id,
                              user: user?.id,
                              id: _oeAssistido?.id,
                            });
                          }
                        }}
                        IsResidenciaMedica={true}
                      />
                    </>
                  ) : objetoSelecionado?.tipoOe === "Flashcard" ? (
                    <Flashcard
                      objetoEducacional={{
                        id: objetoSelecionado.id,
                        attributes: {
                          Descricao: "",
                          Titulo: objetoSelecionado.titulo,
                          ImagemCapa: objetoSelecionado.imagemUrl,
                          ImagemPreview: objetoSelecionado.imagemUrl,
                          TempoMedioDuracao: objetoSelecionado.tempoDuracao,
                          Resumo: objetoSelecionado.resumo,
                          Quizzes: {
                            data: objetoSelecionado.questionario.map(
                              (a: any) => ({
                                id: a.id,
                              }),
                            ),
                          },
                          flashcards: {
                            data: objetoSelecionado?.flashcards?.map(
                              (a: any) => ({
                                id: a.id,
                                attributes: {
                                  pergunta: a.pergunta,
                                  resposta: a.resposta,
                                },
                              }),
                            ),
                          },
                        },
                      }}
                    />
                  ) : objetoSelecionado?.tipoOe === "Texto" ? (
                    <>
                      {objetoSelecionado?.secaoTexto?.map(
                        (text: any, index: number) => (
                          <div key={text?.id}>
                            <h2>{text?.titulo}</h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: text?.descricao,
                              }}
                            />
                          </div>
                        ),
                      )}
                      <div data-button-finish>
                        <Button
                          data-testid="btn-concluir-conteudo"
                          disabled={finalizado}
                          type="button"
                          variant="primary"
                          onClick={(ev) => {
                                handleFinish(ev, objetoSelecionado);
                                handleNextOe(objetoSelecionado)
                              }}
                        >
                          {finalizado ? "Concluído" :  (playListFinalizada ? "Ir para o próximo" : "Concluir Conteúdo")}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Vimeo
                        pip={true}
                        showTitle={false}
                        start={_oeAssistido?.attributes?.segundos || 0}
                        onTimeUpdate={(ev: any) => {
                          updateTime(ev);
                          if (objetoSelecionado?.idVideoAudio > 0) {
                            localStorage.setItem(
                              "UPDATE_VIDEO",
                              JSON.stringify({
                                id: _oeAssistido?.id,
                                infograficoId:
                                  _oeAssistido?.attributes?.ImagemOeId,
                                leituraId:
                                  _oeAssistido?.attributes?.LeituraOeId,
                                oe: objetoSelecionado.id,
                                partialDuration: parseInt(
                                  ev.seconds.toString().split(".")[0],
                                ),
                                totalDuration: parseInt(
                                  ev.duration.toString().split(".")[0],
                                ),
                                user: user?.id,
                                videoAudioId: objetoSelecionado?.idVideoAudio,
                              }),
                            );
                          }
                          handleTimeUpdateRef.current(ev, objetoSelecionado);
                        }}
                        onEnd={(e: any) => handleFinish(e)}
                        // onPause={(e: any) => handleStartStop(e)}
                        onPlay={(e: any) => handlePlayEvent(e)}
                        controls={true}
                        autoplay={false}
                        speed={true}
                        key={objetoSelecionado?.id}
                        id={objetoSelecionado?.id}
                        video={
                          playlistsDetalhe?.detalhesPlaylist?.find(
                            (a) => a.id === Number(objetoSelecionado?.id),
                          )?.videoUrl ?? ""
                        }
                        className="relative overflow-hidden rounded-xl"
                        responsive
                      />
                      {playListFinalizada && isShowProximaAula && (
                        <div className="flex w-full justify-end">
                          <Button
                            data-testid="btn-ir-para-proxima-aula"
                            type="button"
                            className="button button-primary !my-4 self-end"
                            onClick={() => handleNextOe(objetoSelecionado)}
                          >
                            Ir para o próximo
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              <div className="flex flex-col gap-6 pb-10">
                <h3 className="mt-10 text-xxl font-semibold leading-[26.82px] text-[#004F92]">
                  {
                    playlistsDetalhe?.detalhesPlaylist?.find(
                      (a) => a.id === objetoSelecionado?.id,
                    )?.titulo
                  }
                </h3>
                <p className="text-sm font-normal leading-22 text-[#111316]">
                  {
                    playlistsDetalhe?.detalhesPlaylist?.find(
                      (a) => a.id === objetoSelecionado?.id,
                    )?.resumo
                  }
                </p>
                <div className="flex max-w-[750px] flex-wrap gap-2 md:w-full">
                  {playlistsDetalhe?.detalhesPlaylist
                    ?.find((a) => a.id === objetoSelecionado?.id)
                    ?.palavrasChaves?.map((m, index) => (
                      <Tags
                        className="bg-[#E5F0F2] [&>span]:text-[#107B95]"
                        key={index}
                        isSearchable={true}
                        title={m.descricao}
                      />
                    ))}
                </div>
              </div>
            </div>
            <ScrollArea className="flex w-full flex-row gap-1 border-b md:w-[464px]">
              <div className="flex flex-col gap-1">
                {playlistsDetalhe &&
                  playlistsDetalhe?.detalhesPlaylist &&
                  playlistsDetalhe?.detalhesPlaylist.map(
                    (oe: PlaylistDetalhe, index: number) => (
                      <CardsMultiplos
                        key={index}
                        objeto={oe}
                        index={index}
                        videoAtual={null}
                        loading={false}
                        oesAssistidos={user?.attributes?.OesConsumidosParcialmente?.data.filter(
                          (a) =>
                            (a.attributes?.VideoAudioId &&
                              a.attributes?.VideoAudioId?.toString() ===
                                oe.idVideoAudio?.toString()) ||
                            a.attributes?.Oes_Assistidos?.data?.some(
                              (a) => a.id?.toString() === oe.id?.toString(),
                            ),
                        )}
                        setObjetoSelecionado={(e) => {
                          setObjetoSelecionado(e);
                        }}
                        objetoSelecionado={objetoSelecionado}
                        quantidadeOe={
                          playlistsDetalhe?.detalhesPlaylist?.length
                        }
                      />
                    ),
                  )}
              </div>
              {playlistsDetalhe?.banner?.bannerFixo && (
                <div>
                  <img
                    src={playlistsDetalhe?.banner?.bannerFixo}
                    alt="banner"
                    className="h-auto min-h-[188px] w-full cursor-pointer overflow-hidden rounded-xl  object-contain object-top"
                    onClick={() => {
                      window.open(playlistsDetalhe?.banner?.linkUrl, "_blank");
                    }}
                  />
                </div>
              )}
            </ScrollArea>
          </div>
        </Container>
      </div>
      {objetoSelecionado && (
        <>
          <BarraInteracaoOe
            oe={
              {
                id: objetoSelecionado?.id!,
                titulo: objetoSelecionado?.titulo!,
                descricao: objetoSelecionado?.resumo!,
                url: objetoSelecionado?.videoUrl!,
                imagem: objetoSelecionado?.bannerImagemUrl!,
              } as ObjetoEducacionalEntity
            }
            user={user}
          />
          <AboutAuthors
            oe={
              {
                id: objetoSelecionado?.id!,
                titulo: objetoSelecionado?.titulo!,
                descricao: objetoSelecionado?.resumo!,
                url: objetoSelecionado?.videoUrl!,
                imagem: objetoSelecionado?.bannerImagemUrl!,
              } as ObjetoEducacionalEntity
            }
            Autores={
              objetoSelecionado?.autores?.map((a: any) => ({
                id: a.id,
                attributes: {
                  nome_exibicao: a.nome,
                  foto: {
                    data: {
                      attributes: {
                        url: a.fotoPerfil,
                      },
                    },
                  },
                  email: a.email,
                  linkCurriculo: a.linkCurriculo,
                  nomeProjeto: a.nomeProjeto,
                  linkProjeto: a.linkProjeto,
                  cargo: a.cargo,
                },
              })) as PerfilDeAutorEntity[]
            }
          />
        </>
      )}
    </>
  );
}

function CardsMultiplos({
  index,
  objeto,
  loading,
  videoAtual,
  oesAssistidos,
  setObjetoSelecionado,
  objetoSelecionado,
  quantidadeOe,
}: CardsMultiplosProps) {
  const scrollTop = useScrollTop();

  const finalizado =
    (oesAssistidos?.filter(
      (a) => a.attributes?.segundos! >= a.attributes?.duracao!,
    ).length || 0) > 0;

  const location = useLocation();
  const isPlaylistPersonalizada = location.pathname.includes(
    "detalhe-personalizada",
  );

  const getIcon = (type: string) => {
    switch (type) {
      case "Vídeo":
        return <Icon.IconResidenciaVideo />;
      case "Questionário":
        return <Icon.IconResidenciaQuiz />;
      case "Texto":
        return <Icon.IconResidenciaTexto />;
      case "Imagem":
        return <Icon.IconResidenciaImagem />;
      case "PDF":
        return <Icon.IconResidenciaPDF />;
      case "Flashcard":
        return <Icon.IconResidenciaImagem />;
      case "Áudio":
        return <Icon.IconResidenciaAudio />;
    }
  };

  return (
    <>
      <div
        key={index}
        onClick={() => {
          setObjetoSelecionado(objeto);
          scrollTop();
        }}
        className={cn(
          "bg-white flex h-[85px] w-full cursor-pointer flex-row items-center justify-between rounded-xl  !px-6 py-[16px] last:mb-2 ",
          objeto.id.toString() === objetoSelecionado?.id?.toString()
            ? "border-[1px] border-[#01B092]"
            : "hover:border-[1px] hover:border-[#01B092]",
        )}
        style={{
          boxShadow: "0px 2px 4px 0px #0B1F4D14",
        }}
      >
        <span className="mr-2 w-2 text-xxl font-bold text-black-900">
          {index + 1}
        </span>

        <div className="ml-2 flex h-full w-full flex-col justify-between gap-2">
          <div>
            <span
              className={cn(`line-clamp-2 text-xs font-medium text-[#111316]`)}
            >
              {objeto?.titulo}
            </span>
          </div>

          <div className="mt-1 flex w-full flex-row items-center gap-1">
            {getIcon(objeto.tipoOe)}
            <span className="text-xxs font-medium leading-none  text-[#626466]">
              {`${objeto?.duracao} min`}
            </span>
          </div>
        </div>
        {objeto.tipoOe === "Flashcard" ? (
          <></>
        ) : (
          <div className="flex h-full items-center px-2">
            {loading && objeto?.id === objeto?.id ? (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="h-5 w-5 animate-spin fill-[#00B193] text-slate-300 dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            ) : finalizado ? (
              <Icon.IconCheckBoxSelected />
            ) : (
              <Icon.IconCheckBox />
            )}
          </div>
        )}
        {isPlaylistPersonalizada && (
          <MoreOptions
            idOe={Number(objetoSelecionado.id)}
            quantidadeOe={quantidadeOe}
          />
        )}
      </div>
    </>
  );
}

export function MoreOptions({
  idOe,
  quantidadeOe,
}: {
  idPlaylist?: number;
  idOe?: number;
  quantidadeOe?: number;
}) {
  const params = useParams<{ id: string }>();
  const mostrarConfirmDeleteItem = useModalDeleteItem();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button>
            <EllipsisVertical className="size-4" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            onClick={() => {
              mostrarConfirmDeleteItem.mostrar({
                idOe: Number(idOe),
                OeQuantidade: Number(quantidadeOe),
                idPlaylist: Number(params.id),
              });
            }}
            className="flex cursor-pointer gap-3 px-3 py-2 text-xs text-black-900 hover:bg-slate-50"
          >
            <Trash2 className="size-5" />
            Remover da playlist
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
