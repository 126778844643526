import React, { useState, useEffect, useContext, useRef } from "react";
import Vimeo, {
  PauseEvent,
  PlayEvent,
  TimeUpdateEvent,
} from "@u-wave/react-vimeo";
import {
  ComponentSecaoVideoAudioVideoAudio,
  OEsAssistidosPorUsuarioEntity,
  OEsAssistidosPorUsuarioRelationResponseCollection,
  ObjetoEducacional,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../../../generated/graphql";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../../hooks/useGtm";
import useOeConsume from "../../../../../hooks/useOeConsume";

import * as Icon from "../../../../../assets/svgs";

import {
  TIPO_AREA_PAGINA,
  TIPO_PAGINA,
} from "../../../../../hooks/useBannerApi";

import { OEBackButton } from "../../../../../shared/components/OEBackButton";
import { useModalConclusao } from "../../../components";
import {
  UserContext,
  getUserContext,
} from "../../../../../context/UserContext";

import "./styles.scss";
import classNames from "classnames";
import { useUtils } from "../../../../../shared/utils";
import {
  Banner,
  loadBanner,
} from "../../../../../graphql/collections/queries/banner.query";
import Container from "../../../../../shared/components/Container";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../../../../shared/components/ui/breadcrumb";
import Rotulo from "../../../../../shared/components/rotulos";
import Tags from "../../../../../shared/components/Tags";
import { cn } from "../../../../../lib/utils";
import { Link, useParams } from "react-router-dom";
import { BannerSection } from "../../../../../shared/components";
import { Button } from "../../../../../shared/components/ui/button";

interface VideoWatchProps {
  oe: ObjetoEducacionalEntity;
  videoAtivo: ComponentSecaoVideoAudioVideoAudio | undefined;
  user?: UsersPermissionsUserEntity;
  _oeAssistido: any;
  listCardOesVideos?: ObjetoEducacionalEntity[];
}

interface OesConsumidosParcialmente {
  VideoAudioId?: number;
  segundos?: number;
}

interface OE {
  id: string;
}

export const OeVideoWatch: React.FC<VideoWatchProps> = (props) => {
  const { mostrar: mostrarModalConclusao } = useModalConclusao({
    oe: props.oe,
  });
  const refDivVideo = useRef<HTMLDivElement>(null);
  const { user } = useContext(UserContext);
  const { getCurrentTime, updateOeConsume, saveOeConsume } = useOeConsume();
  const [showBanner, setShowBanner] = useState(false);
  const [lastShowBanner, setLastShowBanner] = useState<Number>(0);
  const [mostraModalConclusao, setMostraModalConclusao] = useState(true);
  const [rolouScroll, setRolouScroll] = useState<boolean>(false);
  const gtm = useGtm();
  const params = useParams<{ videoId: string }>();

  const [videoAtual, setVideoAtual] = useState<any>();

  useEffect(() => {
    if (!props.oe) return;

    if (!!params && !!params.videoId) {
      const _videoAtual = props.oe.attributes?.VideoAudio?.find(
        (item) => item.id === params.videoId,
      );

      if (!_videoAtual) {
        window.location.href = "/";
        return;
      }

      setVideoAtual(_videoAtual);
    }
  }, [props.oe, params]);

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: props?.oe.id! ? Number(props?.oe.id!) : undefined,
      areaDeConhecimentoId:
        props?.oe.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id!)) ||
        [],
      subAreaDeConhecimentoId:
        props?.oe.attributes?.SubAreaConhecimento?.data?.map((a) =>
          Number(a.id!),
        ) || [],
      publicoAlvoId:
        props?.oe.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) ||
        [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [props.oe]);

  useEffect(() => {
    if (!props.oe || !props.user) {
      return;
    }
    if (props._oeAssistido && !!props.user) {
      updateOeConsume({
        id: props._oeAssistido.attributes.id,
        infograficoId: props._oeAssistido.attributes.ImagemOeId,
        leituraId: props._oeAssistido.attributes.LeituraOeId,
        oe: props.oe.id,
        partialDuration: props._oeAssistido.attributes.segundos,
        totalDuration: props._oeAssistido.attributes.duracao,
        user: props.user.id,
        videoAudioId: videoAtual?.id as any,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oe, props.user]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [refDivVideo.current, refDivVideo.current?.offsetTop]);

  const handleShowBanner = (currentPercent: Number) => {
    if (showBanner === false && currentPercent !== lastShowBanner) {
      setLastShowBanner(currentPercent);
      setShowBanner(true);
    }
  };

  const handleScroll = (event: any) => {
    let scrollTop = window.scrollY || 0;

    setRolouScroll(scrollTop > 10);
  };

  const [onFinish, setOnFinish] = useState(false);

  useEffect(() => {
    setOnFinish(false);
  }, [videoAtual?.id]);

  const ultimoEpisodio = props.oe.attributes?.VideoAudio?.find((item) => {
    return item.id === props.oe.attributes?.VideoAudio?.slice(-1)[0].id;
  });

  async function handleNextVideo() {
    const index =
      props.oe.attributes?.VideoAudio?.findIndex(
        (item) => item.id === videoAtual?.id,
      ) || 0;

    if (index === -1) {
      return;
    }

    if (index === (props.oe.attributes?.VideoAudio?.length || 0) - 1) {
      return;
    }

    const nextVideo = props.oe.attributes?.VideoAudio?.[index + 1];

    if (!nextVideo) {
      return;
    }
    window.location.href = `/oe/${props.oe.id}/video/${nextVideo.id}`;
  }

  const onTimeUpdate = async (e: TimeUpdateEvent | PauseEvent) => {
    if (!e || !props.user) {
      return;
    }

    const userLoc = getUserContext();

    const { seconds, duration } = e;

    if (seconds === 0) {
      return;
    }

    let newDuration = duration;
    if (newDuration == Infinity || newDuration == 0) {
      newDuration = 60 * 60 * 1000 * 3;
    }

    if (seconds >= duration - 5 && ultimoEpisodio?.id !== videoAtual?.id) {
      setOnFinish(true);
      await save(duration, newDuration);
    }

    const totalPercentual = (seconds / newDuration) * 100;

    const ArrayPerc = [25, 50, 90];
    var currentPercent = Math.round(totalPercentual);

    if (ArrayPerc.indexOf(currentPercent) > -1) {
      handleShowBanner(currentPercent);
    }

    const oeAssistido =
      userLoc?.attributes?.OesConsumidosParcialmente?.data.find(
        (item) =>
          item.attributes?.VideoAudioId &&
          item.attributes?.VideoAudioId!.toString() ===
            videoAtual?.id.toString(),
      );

    localStorage.setItem(
      "UPDATE_VIDEO",
      JSON.stringify({
        id: oeAssistido?.id,
        infograficoId: oeAssistido?.attributes?.ImagemOeId,
        leituraId: oeAssistido?.attributes?.LeituraOeId,
        oe: props.oe.id,
        partialDuration: parseInt(seconds?.toString()?.split(".")[0]),
        totalDuration: parseInt(newDuration?.toString()?.split(".")[0]),
        user: userLoc?.id,
        videoAudioId: videoAtual?.id as any,
      }),
    );

    if (
      !!props.oe.attributes?.PossuiCertificado &&
      totalPercentual >= 100 &&
      mostraModalConclusao
    ) {
      await save(seconds, newDuration);

      let oesConsumidas = userLoc?.attributes?.OesConsumidosParcialmente;

      if (oesConsumidas) {
        const cons: number = oesConsumidas.data?.findIndex(
          (i) => i.attributes?.VideoAudioId == videoAtual?.id?.toString(),
        );
        let data = oesConsumidas.data;
        data.map((a, index) =>
          index == cons
            ? {
                ...a,
                attributes: {
                  ...a.attributes,
                  segundos: seconds,
                },
              }
            : a,
        );
        oesConsumidas = {
          ...oesConsumidas,
          data,
        };
      }

      if (
        props.oe.attributes?.VideoAudio &&
        props.oe.attributes?.VideoAudio.length === 1
      ) {
        mostrarModalConclusao();
        setMostraModalConclusao(true);
      }

      if (
        props.oe.attributes?.VideoAudio &&
        props.oe.attributes?.VideoAudio.length > 1
      ) {
        let totalWatched = 0;
        let watchedAllVideo = true;
        let finishAllVideo = true;

        for (let oe of props.oe.attributes?.VideoAudio) {
          watchedAllVideo =
            watchedAllVideo &&
            !!oesConsumidas &&
            oesConsumidas.data.findIndex(
              (e) => e.attributes?.VideoAudioId?.toString() == oe.id,
            ) > -1;
          finishAllVideo =
            finishAllVideo &&
            !!oesConsumidas &&
            oesConsumidas.data
              .filter((a) => a.attributes?.VideoAudioId?.toString() == oe.id)
              .reduce((ant, prox) => {
                let a = ant || 0;
                a = a + prox.attributes?.segundos!;
                return a;
              }, 0) >=
              oesConsumidas.data.find(
                (a) => a.attributes?.VideoAudioId?.toString() == oe.id,
              )?.attributes?.duracao!;
        }

        oesConsumidas &&
          oesConsumidas.data?.map((p) => {
            const hasVideoIdOe =
              props.oe.attributes?.VideoAudio &&
              props.oe.attributes?.VideoAudio.filter(
                ({ id: idOE }: OE) =>
                  parseInt(idOE) === p.attributes?.VideoAudioId,
              );

            if (
              hasVideoIdOe &&
              hasVideoIdOe.length > 0 &&
              !!p.attributes?.segundos
            ) {
              totalWatched += p.attributes?.segundos / 60;
            }

            return totalWatched;
          });

        if (finishAllVideo && watchedAllVideo) {
          mostrarModalConclusao();
          setMostraModalConclusao(true);
        } else if (
          userLoc?.attributes?.OesConsumidosParcialmente?.data.filter(
            (item) =>
              item.attributes?.Oes_Assistidos &&
              item.attributes?.Oes_Assistidos?.data.length > 0 &&
              item.attributes?.Oes_Assistidos?.data[0]?.id === props.oe.id &&
              item.attributes?.duracao &&
              item.attributes?.segundos &&
              item.attributes?.duracao === item.attributes?.segundos,
          ).length === props.oe.attributes?.VideoAudio.length
        ) {
          mostrarModalConclusao();
          setMostraModalConclusao(true);
        }
      }
    }

    return { seconds, duration: newDuration };
  };

  const { formatTitle } = useUtils();

  const save = async (seconds: number, duration: number) => {
    if (!props.user && !videoAtual?.id) return;

    const userLoc = getUserContext();

    const oeAssistido =
      userLoc?.attributes?.OesConsumidosParcialmente?.data.find(
        (item) =>
          item.attributes?.VideoAudioId &&
          item.attributes?.VideoAudioId!.toString() ===
            videoAtual?.id.toString(),
      );

    /** Para nao perder a declaracao caso o usuario ja tenha concluido o consumo desse video */
    if (
      !!oeAssistido &&
      oeAssistido?.attributes?.duracao &&
      oeAssistido?.attributes?.duracao > 0 &&
      oeAssistido?.attributes?.duracao === oeAssistido?.attributes?.segundos
    ) {
      return;
    }

    const videoAudioId = videoAtual?.id || "";

    await saveOeConsume({
      id: oeAssistido?.id,
      videoAudioId: parseInt(videoAudioId),
      user: userLoc?.id,
      partialDuration: parseInt(seconds.toString().split(".")[0]),
      totalDuration: parseInt(duration.toString().split(".")[0]),
      oe: props.oe.id,
    });
  };

  const handleStartStop = async (e: PauseEvent) => {
    const time = await onTimeUpdate(e);
    if (!!time) {
      let newDuration = time.duration;
      if (newDuration == Infinity) {
        newDuration = 60 * 60 * 1000 * 3;
      }
      await save(time.seconds, newDuration);
    }
  };

  const handlePlayEvent = async (e: PlayEvent) => {
    await handleStartStop(e);

    // GTM doc linha 86!!!
    gtm.push(
      EDataLayerEventCategory.ADE_OE_INTERACAO_VIDEO,
      EDataLayerEventAction.CLIQUE,
      {
        label: formatTitle(props.oe.attributes?.Titulo!),
      },
    );
  };

  const closeBanner = () => {
    setShowBanner(!showBanner);
  };

  return (
    <>
      {!props.oe.attributes?.VideoAudio ||
      props.oe.attributes?.VideoAudio.length === 1 ? (
        <div data-video-wath-container>
          <div
            data-video-container
            ref={refDivVideo}
            className={classNames({
              "video-live": !!props.videoAtivo?.url_chat,
              fixar: rolouScroll,
            })}
          >
            <section data-video-container-inner className="flex items-center">
              <Container className="relative ">
                <header className=" absolute top-5">
                  <OEBackButton />
                </header>

                {!!user && !!props.videoAtivo && !!props.videoAtivo?.URL && (
                  <div data-video-content>
                    {
                      <Vimeo
                        pip={true}
                        showTitle={false}
                        start={props?._oeAssistido?.attributes.segundos || 0}
                        onTimeUpdate={(ev: any) => onTimeUpdate(ev)}
                        onEnd={(e: any) => handleStartStop(e)}
                        onPause={(e: any) => handleStartStop(e)}
                        onPlay={(e: any) => handlePlayEvent(e)}
                        controls={true}
                        autoplay={true}
                        speed={true}
                        video={props.videoAtivo?.URL!}
                        className="player-video"
                      />
                    }
                  </div>
                )}
                {(() => {
                  let bannersFixo = banners?.filter(
                    (a) =>
                      a.tipoPagina === TIPO_PAGINA.OE &&
                      a.areaPagina === TIPO_AREA_PAGINA.FIXO &&
                      ((!!user?.id && a.ocultarLogado === false) || !user?.id),
                  );

                  return (
                    <>
                      {showBanner && !!bannersFixo && !!bannersFixo.length && (
                        <div
                          data-banner
                          style={{
                            zIndex: 99999,
                          }}
                          className={
                            !user
                              ? "deslogado"
                              : "fixed bottom-0 left-0 right-0 z-50 mx-auto flex w-full flex-col items-center justify-center md:w-[728px]"
                          }
                        >
                          <BannerSection
                            items={bannersFixo}
                            fixo={true}
                            onClose={closeBanner}
                            showBanner={showBanner}
                          />
                        </div>
                      )}
                    </>
                  );
                })()}
              </Container>
            </section>
          </div>
          {!!props.videoAtivo?.url_chat && (
            <div
              data-video-wath-chat
              className={classNames({ "fixar-chat": rolouScroll })}
            >
              <iframe
                src={props.videoAtivo?.url_chat!}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-[#F5F8FF]">
          <Container className="py-6">
            <div className="flex w-full flex-col items-start justify-start gap-3 !text-start ">
              <Breadcrumb className="flex justify-start !text-xxs font-normal  md:text-xs">
                <BreadcrumbList>
                  <BreadcrumbItem>
                    <BreadcrumbLink href="/">Home</BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <BreadcrumbLink href={`/oe/${props?.oe?.id}/video`}>
                      {props?.oe?.attributes?.Titulo}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                    <BreadcrumbLink href={`/oe/${props?.oe?.id}/video`}>
                      {videoAtual?.Titulo}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </BreadcrumbList>
              </Breadcrumb>
              {props?.oe?.attributes?.Rotulos?.data.map((rotulo, index) => (
                <div className="flex">
                  <Rotulo
                    key={index}
                    id={Number(rotulo?.id!)}
                    rotuloName={rotulo.attributes?.titulo ?? ""}
                  />
                </div>
              ))}
              <h3 className="text-xxl font-semibold leading-[26.82px] text-[#004F92]">
                {props?.oe?.attributes?.Titulo}
              </h3>
            </div>
            <div className="mt-8 flex w-full flex-col gap-6 md:flex-row">
              <div className="flex w-full flex-col gap-2">
                {!!user && !!videoAtual && (
                  <div className=" relative flex w-full ">
                    {
                      <Vimeo
                        pip={true}
                        showTitle={true}
                        start={props?._oeAssistido?.attributes.segundos || 0}
                        onTimeUpdate={(ev: any) => onTimeUpdate(ev)}
                        onEnd={(e: any) => handleStartStop(e)}
                        onPause={(e: any) => handleStartStop(e)}
                        onPlay={(e: any) => handlePlayEvent(e)}
                        controls={true}
                        autoplay={true}
                        speed={true}
                        key={videoAtual?.id}
                        id={videoAtual?.id}
                        video={videoAtual?.URL}
                        className="player-video-multiplos"
                        responsive={true}
                      />
                    }
                    {onFinish && (
                      <Button
                        onClick={handleNextVideo}
                        data-testid="btn-next-video"
                        className="absolute bottom-12 right-3 h-[50px] bg-white-200 text-[#004F92] shadow-md hover:text-white-200"
                      >
                        Próximo episódio
                      </Button>
                    )}
                  </div>
                )}
                <div className="flex flex-col gap-6">
                  <h3 className="mt-10 text-xxl font-semibold leading-[26.82px] text-[#004F92]">
                    {videoAtual?.Titulo}
                  </h3>
                  <p className="text-sm font-normal leading-22 text-[#111316]">
                    {props?.oe?.attributes?.Descricao.replace(/<[^>]*>?/gm, "")}
                  </p>
                  <div className="flex max-w-[750px] flex-wrap gap-2 md:w-full">
                    {props?.oe?.attributes?.palavras_chave?.data?.map(
                      (m, index) => (
                        <Tags
                          className="bg-[#E5F0F2] [&>span]:text-[#107B95]"
                          key={index}
                          isSearchable={true}
                          title={m.attributes?.titulo!}
                        />
                      ),
                    )}
                  </div>
                </div>
              </div>
              <div className="flex max-h-[500px] w-full flex-col gap-1 overflow-scroll  border-b md:w-[464px]">
                <h3 className="mb-2 text-lg font-semibold leading-[21.94px] text-black-900">
                  Episódios
                </h3>
                {props?.listCardOesVideos &&
                  props?.listCardOesVideos?.map((oe: any, index) => (
                    <CardsMultiplos
                      objeto={oe}
                      index={index}
                      videoAtual={videoAtual}
                      loading={false}
                      oesAssistidos={user?.attributes?.OesConsumidosParcialmente?.data.filter(
                        (a) => a.attributes?.VideoAudioId == oe.VideoId,
                      )}
                    />
                  ))}
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export interface CardsMultiplosProps {
  objeto: any;
  index: number;
  loading: boolean;
  oesAssistidos?: OEsAssistidosPorUsuarioEntity[];
  videoAtual: any;
}

function CardsMultiplos({
  index,
  objeto,
  loading,
  videoAtual,
  oesAssistidos,
}: CardsMultiplosProps) {
  const getIcon = (type: string) => {
    switch (type) {
      case "Vídeo":
        return <Icon.IconResidenciaVideo />;
      case "Questionário":
        return <Icon.IconResidenciaQuiz />;
      case "Texto":
        return <Icon.IconResidenciaTexto />;
      case "Imagem":
        return <Icon.IconResidenciaImagem />;
      case "PDF":
        return <Icon.IconResidenciaPDF />;
      case "Flashcard":
        return <Icon.IconResidenciaImagem />;
    }
  };

  const getIconThumb = (type: string) => {
    switch (type) {
      case "Vídeo":
        return <Icon.IconPlay />;
      case "Questionário":
        return <Icon.IconQuiz />;
      case "Texto":
        return <Icon.IconText />;
      case "Imagem":
        return <Icon.IconImage />;
      case "PDF":
        return <Icon.IconResidenciaPDFWhite />;
      case "Flashcard":
        return <Icon.IconFlashcard />;
    }
  };

  const finalizado =
    (oesAssistidos?.filter(
      (a) => a.attributes?.segundos! >= a.attributes?.duracao!,
    ).length || 0) > 0;

  return (
    <>
      <Link to={`/oe/${objeto?.id}/video/${objeto?.attributes?.VideoId}`}>
        <div
          key={index}
          onClick={() => {}}
          className={cn(
            "bg-white flex h-[85px]  w-full cursor-pointer flex-row items-center justify-between rounded-xl  !px-6 py-[16px] last:mb-2 ",
            objeto?.VideoId?.toString() === videoAtual?.id?.toString()
              ? "border-[1px] border-[#01B092]"
              : "hover:border-[1px] hover:border-[#01B092]",
          )}
          style={{
            boxShadow: "0px 2px 4px 0px #0B1F4D14",
          }}
        >
          <span className="mr-2 w-2 text-xxl font-bold text-black-900">
            {index + 1}
          </span>

          <div className="ml-4 h-full w-full">
            <span
              className={cn(`line-clamp-2 text-xs font-medium text-[#111316]`)}
            >
              {objeto?.attributes?.Titulo}
            </span>
            <div className="mt-1 flex w-full flex-row items-center gap-2">
              {getIcon(
                objeto?.attributes?.TipoObjeto?.data[0].attributes?.titulo!,
              )}
              <span className="text-xxs font-medium leading-none  text-[#626466]">
                {`${objeto?.attributes?.TempoMedioDuracao} min`}
              </span>
            </div>
          </div>
          {
            <div className="flex h-full items-center px-2">
              {loading && objeto?.id === objeto?.id ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="h-5 w-5 animate-spin fill-[#00B193] text-slate-300 dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </div>
              ) : finalizado ? (
                <Icon.IconCheckBoxSelected />
              ) : (
                <Icon.IconCheckBox />
              )}
            </div>
          }
        </div>
      </Link>
    </>
  );
}
export default CardsMultiplos;
