import React, { useContext } from "react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";

import { NovoUsuarioPageV2 } from "./pages/NovoUsuarioPageV2";

import OEPage from "./pages/OEPage";
import { OEAreaConhecimentoPage } from "./pages/OeAreaConhecimentoPage";
import { OECategoriaPage } from "./pages/OECategoriaPage";

import AreasConhecimento from "./pages/AreasInteressePage";
import { MeuPerfilPage } from "./pages/MeuPerfilPage";
import Favoritos from "./pages/OEFavoritosPage";
import { TopicosPage } from "./pages/TopicosPage";
import AutorListPage from "./pages/AutorPage/Lista";
import AutorDetailsPage from "./pages/AutorPage/Details";
import CertificadoPagev2 from "./pages/CertificadoPageV2";
import EsqueciMinhaSenhaPage from "./pages/EsqueciMinhaSenhaPage";
import { EnsinoEinsteinPage } from "./pages/EnsinoEinsteinPage";
import {
  getLocalStorageValue,
  isAuthenticated,
} from "./shared/services/localStorage.service";
import { AtendimentoPage } from "./pages/AtendimentoPage";

import { CategoriaPatrocinadoresPage } from "./pages/CategoriaPatrocinadoresPage";
import { PatrocinadorPage } from "./pages/PatrocinadorPage";
import { ParceriaPage } from "./pages/ParceriaPage";

import SobrePage from "./pages/SobrePage";

import { ContainerPage } from "./layout/ContainerPage";
import { CategoriaParceriasPage } from "./pages/CategoriaParceriasPage";
import MetodosEnsinoPage from "./pages/MetodosEnsinoPage";
import { SubAreasInteresse } from "./pages/SubAreasInteresse";
import ProfissoesPage from "./pages/ProfissoesPage";
import { CategoriaTemaPage } from "./pages/CategoriaTemaPage";
import { OESubMetodosEnsinoPage } from "./pages/OESubMetodosEnsinoPage";
import AreasInteresseAutorPage from "./pages/AreasInteresseAutorPage";
import { SubAreasInteresseAutor } from "./pages/SubAreasInteresseAutorPage";
import { ListSubAreaInteresse } from "./pages/ProfissoesPage/components/ListSubAreaInteresse";
import ResidenciaMedica from "./pages/ResidenciaMedicaPage";
import ResidenciaMedicaFormulario from "./pages/ResidenciaMedicaFormulario";
import ResidenciaMedicaTrilha from "./pages/ResidenciaMedicaTrilha";
import DegustacaoPos from "./pages/DegustacaoPos";
import ObjetoEducacionalSearch from "./pages/ObjetoEducacionalSearch";
import NovosConteudosInteresse from "./pages/NovosConteudosInteresse";
import ContinueAssistindoOe from "./pages/ContinueAssistindoOe";
import NaoAutenticado from "./pages/Home/NaoAutenticado";
import Autenticado from "./pages/Home/Auntenticado";
import { TOKEN_SECRECT } from "./shared/constants/auth-types";
import SobreResidenciaMedicaPage from "./pages/SobreResidenciaMedicaPage";
import { UserDetalheContext } from "./context/UserPreparaEinsteinContext";
import NotFound from "./pages/NotFound";
import Playlists from "./pages/Playlists";
import PlaylistsLista from "./pages/Playlists/PlaylistLista";
import DetalhePlaylist from "./pages/Playlists/Detalhe";
import PlaylistsListaPersonalizada from "./pages/Playlists/PlaylistListPersonalizada";
import Eventos from "./pages/Eventos";

const RoutesMap: React.FC = () => {
  const data = getLocalStorageValue(TOKEN_SECRECT);
  const { userDetalhe } = useContext(UserDetalheContext);

  const renderPreparaEinstein = () => {
    if (userDetalhe !== null && userDetalhe?.id) {
      return <ResidenciaMedica />;
    } else {
      return <SobreResidenciaMedicaPage />;
    }
  };

  const profileRoutes = [
    "/meu-perfil",
    "/meu-perfil/informacoes-pessoais",
    "/meu-perfil/informacoes-profissionais",
    "/meu-perfil/preferencias-de-conteudo",
    "/meu-perfil/historico/videos",
    "/meu-perfil/historico/audios",
    "/meu-perfil/historico/textos-e-artigos",
    "/meu-perfil/historico/quizzes-e-desafios",
    "/meu-perfil/autores",
  ];

  return (
    <ContainerPage>
      <Switch>
        <Route path="/" exact>
          {data ? <Redirect to="/home" /> : <NaoAutenticado />}
        </Route>

        <Route path="/home" component={Autenticado} exact={true}></Route>
        {/* <Route path="*" exact={true} component={NotFound} /> */}

        <Route path="/atendimento" component={AtendimentoPage} exact={true} />

        <Route path="/novo-usuario" exact={true}>
          <Redirect
            to={{
              pathname: "/novo-usuario-v2/dados-pessoais",
            }}
          />
        </Route>

        <Route
          path="/novo-usuario-v2"
          exact={true}
          component={NovoUsuarioPageV2}
        >
          <Redirect
            to={{
              pathname: "/novo-usuario-v2/dados-pessoais",
            }}
          />
        </Route>
        <Route
          path="/novo-usuario-v2/dados-pessoais"
          component={NovoUsuarioPageV2}
          exact={true}
        />
        <Route
          path="/novo-usuario-v2/dados-profissionais"
          component={NovoUsuarioPageV2}
          exact={true}
        />
        <Route
          path="/novo-usuario-v2/areas-de-interesse"
          component={NovoUsuarioPageV2}
          exact={true}
        />

        <Route
          path="/areas-de-interesse"
          component={AreasConhecimento}
          exact={true}
        />

        <Route path="/topicos" component={TopicosPage} exact={true} />

        <Route
          path="/metodos-ensino"
          component={MetodosEnsinoPage}
          exact={true}
        />
        <Route path="/prepara-einstein" exact render={renderPreparaEinstein} />

        <Route
          path="/continue-assistindo"
          component={ContinueAssistindoOe}
          exact={true}
        />
        <Route
          path="/prepara-einstein/formulario"
          component={ResidenciaMedicaFormulario}
          exact={true}
        />
        <Route
          path="/prepara-einstein/:id"
          component={ResidenciaMedicaTrilha}
          exact={true}
        />
        <Route
          path="/prepara-einstein/:id/:moduloId/:objetoId"
          component={ResidenciaMedicaTrilha}
          exact={true}
        />
        <Route
          path="/novos-conteudos-interesse"
          component={NovosConteudosInteresse}
          exact={true}
        />
        <Route
          path="/central-de-autorias"
          component={AutorListPage}
          exact={true}
        />
        <Route
          path="/autor/:id/detalhes"
          component={AutorDetailsPage}
          exact={true}
        />
        <Route
          path="/autor/:idAutor/detalhes/areas-interesse"
          component={AreasInteresseAutorPage}
          exact={true}
        />
        <Route
          path="/autor/:idAutor/detalhes/oe"
          component={SubAreasInteresseAutor}
          exact={true}
        />
        <Route path="/oe" component={OEPage} exact={true} />
        <Route path="/oe/:id/quiz" component={OEPage} exact={true} />
        <Route path="/oe/:id/quiz/:quizId" component={OEPage} exact={true} />
        <Route
          path="/oe/:id/teste-conhecimento"
          component={OEPage}
          exact={true}
        />
        <Route path="/oe/:id/podcast" component={OEPage} exact={true} />
        <Route path="/oe/:id/video" component={OEPage} exact={true} />
        <Route path="/oe/:id/webcast" component={OEPage} exact={true} />
        <Route path="/oe/:id/live" component={OEPage} exact={true} />
        <Route path="/oe/:id/pdf" component={OEPage} exact={true} />
        <Route path="/oe/:id/video/:videoId" component={OEPage} exact={true} />
        <Route
          path="/oe/:id/webcast/:videoId"
          component={OEPage}
          exact={true}
        />
        <Route path="/oe/:id/live/:videoId" component={OEPage} exact={true} />
        <Route path="/oe/:id/leitura" component={OEPage} />
        <Route
          path="/oe/:id/leitura/preview/"
          component={OEPage}
          exact={true}
        />
        <Route path="/oe/:id/imagem" component={OEPage} />
        <Route path="/oe/:id/pdf/:pdfId" component={OEPage} exact={true} />
        <Route
          path="/ensino-einstein"
          component={EnsinoEinsteinPage}
          exact={true}
        />

        <Route path="/degustacao-pos" component={DegustacaoPos} exact={true} />

        <Route
          path="/trocar-senha/:hash"
          component={EsqueciMinhaSenhaPage}
          exact={true}
        />

        <Route path="/favoritos" component={Favoritos} exact={true} />
        <Route path="/sobre-a-academia" component={SobrePage} exact={true} />

        <Route
          path="/oe/pesquisa"
          component={ObjetoEducacionalSearch}
          exact={true}
        />

        <Route
          path="/metodos-ensino/:name/:id"
          component={OECategoriaPage}
          exact={true}
        />
        <Route
          path="/metodos-ensino/:name/:id/:subname/:subid"
          component={OESubMetodosEnsinoPage}
          exact={true}
        />

        <Route
          component={OESubMetodosEnsinoPage}
          path="/submetodos-ensino/:subname/:subid"
          exact={true}
        />

        <Route
          path="/area-de-interesse/:area/:idarea/:name/:id"
          component={OEAreaConhecimentoPage}
          exact={true}
        />

        <Route
          path="/temas-saude/:name/:id"
          component={OEAreaConhecimentoPage}
          exact={true}
        />

        <Route
          path="/area-de-interesse/:name/:id"
          component={SubAreasInteresse}
          exact={true}
        />

        <Route
          path="/area-do-patrocinador"
          component={CategoriaPatrocinadoresPage}
          exact={true}
        />

        <Route path="/playlists" component={Playlists} exact={true} />
        <Route path="/playlists/:id" component={PlaylistsLista} exact={true} />
        <Route
          path="/playlists-personalizada"
          component={PlaylistsListaPersonalizada}
          exact={true}
        />
        <Route
          path="/playlists/:id/detalhe"
          component={DetalhePlaylist}
          exact={true}
        />
        <Route
          path="/playlists/:id/detalhe-personalizada"
          component={DetalhePlaylist}
          exact={true}
        />

        <Route
          path="/area-do-patrocinador/:name/:id"
          component={PatrocinadorPage}
          exact={true}
        />

        <Route
          path="/parcerias"
          component={CategoriaParceriasPage}
          exact={true}
        />

        <Route
          path="/parcerias/:name/:id"
          component={ParceriaPage}
          exact={true}
        />

        <Route path="/profissoes" component={ProfissoesPage} exact={true} />
        <Route
          path="/profissoes/:id"
          component={ListSubAreaInteresse}
          exact={true}
        />

        <Route path="/eventos" component={Eventos} exact={true} />

        <Route path="/temas-saude" component={CategoriaTemaPage} exact={true} />

        <Route
          path="/oe/:id/certificado"
          render={(props: RouteComponentProps) =>
            isAuthenticated() ? (
              <CertificadoPagev2
                tempoMedioDuracao={""}
                titulo={""}
                {...props}
              />
            ) : (
              <Redirect to="/pagina-nao-encontrada" />
            )
          }
          exact={true}
        />

        {profileRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            exact
            render={(props: RouteComponentProps) =>
              isAuthenticated() ? (
                <MeuPerfilPage {...props} />
              ) : (
                <Redirect to="/pagina-nao-encontrada" />
              )
            }
          />
        ))}

        {/* Rota para Página Não Encontrada */}
        <Route path="/pagina-nao-encontrada" exact component={NotFound} />
        <Route path="*">
          <Redirect to="/pagina-nao-encontrada" />
        </Route>
      </Switch>
    </ContainerPage>
  );
};

export default connect((state) => state)(RoutesMap);
